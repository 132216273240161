import React from 'react'
import NoDataChartSvg from '../../../assets/NoDataSvg/NoDataChartSvg'

const KooperNoDataChart = ({ imageSrc, noDataText, noDataSubText }) => {
  return (
    <div className='no-data-design'>
      {imageSrc ? (
        <img style={{ maxWidth: '100%' }} src={imageSrc} />
      ) : (
        <NoDataChartSvg />
      )}

      {noDataText && <p>{noDataText}</p>}
      {noDataSubText && <span>{noDataSubText}</span>}
    </div>
  )
}

export default KooperNoDataChart
