import React from 'react'

const NoDataTableSvg = () => {
  return (
    <svg
      style={{ maxWidth: '100%' }}
      width='474'
      height='273'
      viewBox='0 0 474 273'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='237.5' cy='136.5' r='119.5' fill='#F0F6FF' />
      <circle cx='442.5' cy='174.5' r='14.5' fill='#D9D9D9' />
      <circle cx='467' cy='204' r='7' fill='#D9D9D9' />
      <circle
        cx='14.5'
        cy='230.5'
        r='14.5'
        transform='rotate(180 14.5 230.5)'
        fill='#D9D9D9'
      />
      <circle
        cx='54'
        cy='98'
        r='7'
        transform='rotate(180 54 98)'
        fill='#D9D9D9'
      />
      <path
        d='M399 42L397.1 47.8C397.003 48.1012 396.835 48.375 396.612 48.599C396.388 48.8229 396.114 48.9905 395.813 49.088L390 51L395.8 52.9C396.101 52.9973 396.375 53.1647 396.599 53.3884C396.823 53.6122 396.99 53.8859 397.088 54.187L399 60L400.9 54.2C400.997 53.8988 401.165 53.625 401.388 53.401C401.612 53.1771 401.886 53.0095 402.187 52.912L408 51L402.2 49.1C401.899 49.0027 401.625 48.8353 401.401 48.6116C401.177 48.3878 401.01 48.1141 400.912 47.813L399 42Z'
        stroke='#D9D9D9'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M148 1L146.1 6.8C146.003 7.10122 145.835 7.37504 145.612 7.59895C145.388 7.82287 145.114 7.99048 144.813 8.088L139 10L144.8 11.9C145.101 11.9973 145.375 12.1647 145.599 12.3884C145.823 12.6122 145.99 12.8859 146.088 13.187L148 19L149.9 13.2C149.997 12.8988 150.165 12.625 150.388 12.401C150.612 12.1771 150.886 12.0095 151.187 11.912L157 10L151.2 8.1C150.899 8.00271 150.625 7.83531 150.401 7.61157C150.177 7.38783 150.01 7.11414 149.912 6.813L148 1Z'
        stroke='#D9D9D9'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M191 254L189.1 259.8C189.003 260.101 188.835 260.375 188.612 260.599C188.388 260.823 188.114 260.99 187.813 261.088L182 263L187.8 264.9C188.101 264.997 188.375 265.165 188.599 265.388C188.823 265.612 188.99 265.886 189.088 266.187L191 272L192.9 266.2C192.997 265.899 193.165 265.625 193.388 265.401C193.612 265.177 193.886 265.01 194.187 264.912L200 263L194.2 261.1C193.899 261.003 193.625 260.835 193.401 260.612C193.177 260.388 193.01 260.114 192.912 259.813L191 254Z'
        stroke='#D9D9D9'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M260.15 33H166C163.239 33 161 35.2386 161 38V235C161 237.761 163.239 240 166 240H309C311.761 240 314 237.761 314 235V93.1378C314 91.916 313.553 90.7366 312.742 89.8221L263.892 34.6843C262.943 33.6132 261.581 33 260.15 33Z'
        fill='white'
        stroke='#AAC5F1'
        stroke-width='4'
      />
      <path
        d='M261 33.5V80C261 86.0751 265.925 91 272 91H315'
        stroke='#AAC5F1'
        stroke-width='4'
      />
      <path
        d='M191.5 92H237'
        stroke='#AAC5F1'
        stroke-width='4'
        stroke-linejoin='round'
      />
      <path
        d='M192 123H284'
        stroke='#AAC5F1'
        stroke-width='4'
        stroke-linejoin='round'
      />
      <path
        d='M192 154H284'
        stroke='#AAC5F1'
        stroke-width='4'
        stroke-linejoin='round'
      />
      <path
        d='M192 184H284'
        stroke='#AAC5F1'
        stroke-width='4'
        stroke-linejoin='round'
      />
      <path
        d='M192 214H284'
        stroke='#AAC5F1'
        stroke-width='4'
        stroke-linejoin='round'
      />
      <path
        d='M162.451 107.426C156.134 109.075 149.47 108.814 143.301 106.675C137.133 104.536 131.737 100.616 127.796 95.4109C123.856 90.2056 121.547 83.9487 121.163 77.4313C120.778 70.9139 122.335 64.4289 125.636 58.7962C128.937 53.1636 133.834 48.6363 139.708 45.7869C145.582 42.9374 152.169 41.8939 158.636 42.7881C165.103 43.6824 171.16 46.4743 176.041 50.8108C180.921 55.1473 184.406 60.8337 186.055 67.1508C187.149 71.3452 187.407 75.7141 186.813 80.0081C186.22 84.3022 184.786 88.4372 182.594 92.1772C180.402 95.9171 177.495 99.1887 174.039 101.805C170.583 104.422 166.645 106.332 162.451 107.426Z'
        fill='#F7F7F7'
      />
      <path
        d='M123.921 133.546L138.691 108.344C147.167 112.331 156.854 112.897 165.737 109.924C174.62 106.951 182.014 100.668 186.382 92.382C190.75 84.0958 191.756 74.4445 189.189 65.4359C186.622 56.4273 180.681 48.755 172.602 44.0153C164.522 39.2755 154.927 37.8331 145.811 39.9881C136.695 42.1431 128.761 47.7297 123.659 55.5856C118.558 63.4415 116.681 72.9619 118.42 82.1661C120.159 91.3704 125.38 99.5499 132.996 105.003L118.222 130.207C118.002 130.581 117.859 130.994 117.8 131.424C117.74 131.854 117.766 132.291 117.876 132.711C117.985 133.13 118.176 133.524 118.438 133.87C118.7 134.216 119.027 134.507 119.401 134.726C119.776 134.945 120.189 135.089 120.619 135.148C121.049 135.208 121.486 135.182 121.905 135.072C122.325 134.963 122.719 134.772 123.065 134.51C123.411 134.248 123.702 133.921 123.921 133.546ZM146.614 46.7446C152.3 45.2608 158.298 45.4962 163.849 47.4211C169.401 49.346 174.257 52.8739 177.803 57.5587C181.35 62.2434 183.428 67.8747 183.774 73.7403C184.12 79.6059 182.719 85.4425 179.748 90.5119C176.777 95.5813 172.37 99.6558 167.083 102.22C161.796 104.785 155.868 105.724 150.047 104.919C144.227 104.114 138.776 101.602 134.384 97.6988C129.991 93.7959 126.855 88.6782 125.371 82.9928C123.39 75.3693 124.515 67.2715 128.497 60.476C132.48 53.6804 138.995 48.7422 146.614 46.7446Z'
        fill='#AAC5F1'
      />
      <path
        d='M148.183 68.2126C147.108 67.7474 146.652 66.3926 147.297 65.4074C148.599 63.4368 150.775 62 153.65 62C156.806 62 158.969 63.4642 160.07 65.2979C161.01 66.8716 161.561 69.8137 160.11 72.0032C158.498 74.4253 156.954 75.1642 156.121 76.7242C155.92 77.0937 155.799 77.3947 155.718 78.0105C155.597 79.0095 154.791 79.7895 153.797 79.7895C152.629 79.7895 151.675 78.7632 151.81 77.5726C151.89 76.8747 152.051 76.1495 152.427 75.4653C153.462 73.5632 155.449 72.4411 156.605 70.7579C157.827 68.9926 157.142 65.6947 153.677 65.6947C152.105 65.6947 151.084 66.5295 150.453 67.5284C149.983 68.3084 149.002 68.5547 148.183 68.2126ZM156.349 85.2632C156.349 86.7684 155.141 88 153.663 88C152.186 88 150.977 86.7684 150.977 85.2632C150.977 83.7579 152.186 82.5263 153.663 82.5263C155.141 82.5263 156.349 83.7579 156.349 85.2632Z'
        fill='#787676'
      />
    </svg>
  )
}

export default NoDataTableSvg
