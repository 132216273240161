import React from 'react'
import KooperNoDataTable from './KooperNoDataTable'
import KooperNoDataChart from './KooperNoDataChart'
import KooperNoDataGeneral from './KooperNoDataGeneral'

const KooperNoData = ({ type = 'general', ...restProps }) => {
  function _renderLayout() {
    switch (type) {
      case 'table':
        return <KooperNoDataTable {...restProps} />
      case 'chart':
        return <KooperNoDataChart {...restProps} />

      default:
        return <KooperNoDataGeneral {...restProps} />
    }
  }

  return _renderLayout()
}

export default KooperNoData
