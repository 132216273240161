import React from 'react'

const NoDataChartSvg = () => {
  return (
    <svg
      style={{ maxWidth: '100%' }}
      width='474'
      height='280'
      viewBox='0 0 474 280'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='273.752'
        height='168'
        rx='8'
        transform='matrix(1 0 0 -1 109 228)'
        fill='#AAC5F1'
      />
      <rect
        width='273.752'
        height='168'
        rx='8'
        transform='matrix(1 0 0 -1 85 210)'
        fill='#AAC5F1'
        fill-opacity='0.33'
      />
      <rect
        x='113.25'
        y='63.5371'
        width='264.556'
        height='160.573'
        rx='8'
        fill='white'
      />
      <rect x='131' y='77' width='139' height='33' rx='3' fill='#EBF3FF' />
      <rect x='141' y='84' width='119' height='7' rx='3' fill='#D9D9D9' />
      <rect x='141' y='95' width='87' height='7' rx='3' fill='#D9D9D9' />
      <rect
        x='263.289'
        y='126.112'
        width='96.9095'
        height='25'
        rx='3'
        fill='#EBF3FF'
      />
      <rect x='272' y='130' width='81' height='7' rx='3' fill='#D9D9D9' />
      <rect x='294' y='141' width='59' height='7' rx='3' fill='#D9D9D9' />
      <rect x='131' y='167' width='141' height='27' rx='3' fill='#EBF3FF' />
      <rect x='139' y='171' width='119' height='7' rx='3' fill='#D9D9D9' />
      <rect x='139' y='182' width='87' height='7' rx='3' fill='#D9D9D9' />
      <path
        d='M282.465 230.483C282.465 237.012 280.529 243.394 276.902 248.823C273.274 254.251 268.119 258.482 262.087 260.98C256.056 263.479 249.418 264.132 243.015 262.859C236.612 261.585 230.73 258.441 226.114 253.825C221.497 249.208 218.353 243.326 217.08 236.923C215.806 230.52 216.46 223.883 218.958 217.851C221.456 211.819 225.687 206.664 231.116 203.037C236.544 199.41 242.926 197.474 249.455 197.474C253.79 197.474 258.082 198.327 262.087 199.986C266.092 201.645 269.731 204.077 272.796 207.142C275.862 210.207 278.293 213.846 279.952 217.851C281.611 221.856 282.465 226.148 282.465 230.483Z'
        fill='#F7F7F7'
      />
      <path
        d='M298.008 274.362L277.352 253.706C283.351 246.512 286.345 237.282 285.711 227.936C285.078 218.59 280.866 209.849 273.951 203.53C267.036 197.21 257.952 193.8 248.587 194.009C239.222 194.218 230.298 198.029 223.672 204.649C217.045 211.27 213.227 220.19 213.01 229.555C212.793 238.919 216.195 248.007 222.508 254.927C228.821 261.847 237.559 266.067 246.904 266.709C256.249 267.351 265.482 264.365 272.681 258.373L293.337 279.033C293.644 279.339 294.008 279.583 294.409 279.749C294.809 279.915 295.239 280 295.672 280C296.106 280 296.536 279.915 296.936 279.749C297.337 279.583 297.701 279.339 298.008 279.033C298.315 278.726 298.558 278.362 298.724 277.961C298.89 277.56 298.975 277.131 298.975 276.697C298.975 276.263 298.89 275.834 298.724 275.433C298.558 275.033 298.315 274.668 298.008 274.362ZM219.75 230.484C219.75 224.608 221.493 218.864 224.757 213.978C228.021 209.093 232.661 205.285 238.09 203.036C243.518 200.788 249.492 200.199 255.255 201.346C261.018 202.492 266.311 205.322 270.466 209.476C274.621 213.631 277.45 218.925 278.597 224.688C279.743 230.451 279.155 236.424 276.906 241.853C274.658 247.281 270.85 251.921 265.964 255.186C261.079 258.45 255.335 260.192 249.459 260.192C241.582 260.184 234.031 257.051 228.461 251.481C222.892 245.912 219.759 238.36 219.75 230.484Z'
        fill='#AAC5F1'
      />
      <path
        d='M243.183 223.213C242.108 222.747 241.652 221.393 242.297 220.407C243.599 218.437 245.775 217 248.65 217C251.806 217 253.969 218.464 255.07 220.298C256.01 221.872 256.561 224.814 255.11 227.003C253.498 229.425 251.954 230.164 251.121 231.724C250.92 232.094 250.799 232.395 250.718 233.011C250.597 234.009 249.791 234.789 248.797 234.789C247.629 234.789 246.675 233.763 246.81 232.573C246.89 231.875 247.051 231.149 247.427 230.465C248.462 228.563 250.449 227.441 251.605 225.758C252.827 223.993 252.142 220.695 248.677 220.695C247.105 220.695 246.084 221.529 245.453 222.528C244.983 223.308 244.002 223.555 243.183 223.213ZM251.349 240.263C251.349 241.768 250.141 243 248.663 243C247.186 243 245.977 241.768 245.977 240.263C245.977 238.758 247.186 237.526 248.663 237.526C250.141 237.526 251.349 238.758 251.349 240.263Z'
        fill='#C4C4C4'
      />
      <circle cx='442.5' cy='174.5' r='14.5' fill='#D9D9D9' />
      <circle cx='467' cy='204' r='7' fill='#D9D9D9' />
      <circle
        cx='14.5'
        cy='230.5'
        r='14.5'
        transform='rotate(180 14.5 230.5)'
        fill='#D9D9D9'
      />
      <circle
        cx='54'
        cy='98'
        r='7'
        transform='rotate(180 54 98)'
        fill='#D9D9D9'
      />
      <path
        d='M399 42L397.1 47.8C397.003 48.1012 396.835 48.375 396.612 48.599C396.388 48.8229 396.114 48.9905 395.813 49.088L390 51L395.8 52.9C396.101 52.9973 396.375 53.1647 396.599 53.3884C396.823 53.6122 396.99 53.8859 397.088 54.187L399 60L400.9 54.2C400.997 53.8988 401.165 53.625 401.388 53.401C401.612 53.1771 401.886 53.0095 402.187 52.912L408 51L402.2 49.1C401.899 49.0027 401.625 48.8353 401.401 48.6116C401.177 48.3878 401.01 48.1141 400.912 47.813L399 42Z'
        stroke='#D9D9D9'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M148 1L146.1 6.8C146.003 7.10122 145.835 7.37504 145.612 7.59895C145.388 7.82287 145.114 7.99048 144.813 8.088L139 10L144.8 11.9C145.101 11.9973 145.375 12.1647 145.599 12.3884C145.823 12.6122 145.99 12.8859 146.088 13.187L148 19L149.9 13.2C149.997 12.8988 150.165 12.625 150.388 12.401C150.612 12.1771 150.886 12.0095 151.187 11.912L157 10L151.2 8.1C150.899 8.00271 150.625 7.83531 150.401 7.61157C150.177 7.38783 150.01 7.11414 149.912 6.813L148 1Z'
        stroke='#D9D9D9'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M191 254L189.1 259.8C189.003 260.101 188.835 260.375 188.612 260.599C188.388 260.823 188.114 260.99 187.813 261.088L182 263L187.8 264.9C188.101 264.997 188.375 265.165 188.599 265.388C188.823 265.612 188.99 265.886 189.088 266.187L191 272L192.9 266.2C192.997 265.899 193.165 265.625 193.388 265.401C193.612 265.177 193.886 265.01 194.187 264.912L200 263L194.2 261.1C193.899 261.003 193.625 260.835 193.401 260.612C193.177 260.388 193.01 260.114 192.912 259.813L191 254Z'
        stroke='#D9D9D9'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default NoDataChartSvg
