import React from 'react'
import NoDataGeneralSvg from '../../../assets/NoDataSvg/NoDataGeneralSvg'

const KooperNoDataGeneral = ({ imageSrc, noDataText, noDataSubText }) => {
  return (
    <div className='no-data-design'>
      {imageSrc ? (
        <img style={{ maxWidth: '100%' }} src={imageSrc} />
      ) : (
        <NoDataGeneralSvg />
      )}

      {noDataText && <p>{noDataText}</p>}
      {noDataSubText && <span>{noDataSubText}</span>}
    </div>
  )
}

export default KooperNoDataGeneral
